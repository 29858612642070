import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d8bb9f08"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "built-in-auth" }
const _hoisted_2 = { class: "built-in-auth-header" }
const _hoisted_3 = { class: "has-text-contents-title has-text-title" }
const _hoisted_4 = { class: "built-in-auth-body has-background-contents" }
const _hoisted_5 = { class: "built-in-auth-queries" }
const _hoisted_6 = { class: "built-in-auth-form level-left" }
const _hoisted_7 = { class: "built-in-auth-form-name" }
const _hoisted_8 = { class: "built-in-auth-form level-left" }
const _hoisted_9 = { class: "built-in-auth-form-name" }
const _hoisted_10 = { class: "built-in-auth-error-message-area" }
const _hoisted_11 = { id: "built-in-auth-error-message" }
const _hoisted_12 = { class: "level is-mobile" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppInput = _resolveComponent("AppInput")!
  const _component_AppButton = _resolveComponent("AppButton")!
  const _component_ButtonIcon = _resolveComponent("ButtonIcon")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("header", _hoisted_2, [
      _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.$t('label.builtinLogin')), 1)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("form", {
        id: "built-in-login",
        onSubmit: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.login && _ctx.login(...args)), ["prevent"]))
      }, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.$t('label.userId')), 1),
            _createVNode(_component_AppInput, {
              modelValue: _ctx.form.cardId,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.cardId) = $event)),
              inputId: "built-in-auth-form-input-cardId",
              type: "text",
              name: "cardId",
              required: ""
            }, null, 8, ["modelValue"])
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.$t('label.password')), 1),
            _createVNode(_component_AppInput, {
              modelValue: _ctx.form.password,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.password) = $event)),
              inputId: "built-in-auth-form-input-password",
              type: "password",
              name: "password",
              required: ""
            }, null, 8, ["modelValue"])
          ]),
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.errorMessage), 1)
          ])
        ]),
        _createElementVNode("nav", _hoisted_12, [
          _createVNode(_component_AppButton, {
            styled: "is-none",
            buttonId: "built-in-auth-cancel-button"
          }),
          _createVNode(_component_ButtonIcon, {
            text: _ctx.$t('label.login'),
            icon: "square-arrow-up",
            type: "submit",
            styled: "is-accent",
            buttonId: "built-in-auth-button"
          }, null, 8, ["text"])
        ])
      ], 32)
    ])
  ]))
}