
import { defineComponent, reactive, computed } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import AppInput from '../atoms/AppInput.vue'
import ButtonIcon from '../molecules/ButtonIcon.vue'
import { AuthorityRole, BuiltinLoginRequest } from '@/data/@types/Authentication'
import { MUTATIONS } from '@/store/mutations-types'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  components: {
    AppInput,
    ButtonIcon,
  },
  setup () {
    const router = useRouter()
    const route = useRoute()
    const store = useStore()
    const i18n = useI18n()

    const form = reactive<BuiltinLoginRequest>({ cardId: '', password: '' })
    const errorMessage = computed(() => {
      return store.getters.hasErrorInLoginPage ? i18n.t('message.loginError.authenticationError') : ''
    })
    const hasError = computed(() => {
      return store.getters.hasErrorInLoginPage
    })
    const login = async () => {
      const data = new FormData()
      data.append('cardId', form.cardId)
      data.append('password', form.password)
      await store.dispatch('builtinLogin', data)
      if (!hasError.value) {
        store.commit(MUTATIONS.LOGIN_NOTIFICATION)

        const isRekion = route.meta.isRekion
        const loggedInUser = store.getters.loggedInUser
        if (isRekion && loggedInUser?.role?.includes(AuthorityRole.REKION_ADMIN)) {
          router.push('/manage')
          return
        }
        router.push('/')
      }
    }

    return {
      login,
      form,
      errorMessage,
      hasError,
    }
  },
})
